<template>
    <div>
        <div v-if="type == 'harmony-like' || type == 'harmony-follow'">
            <strong> {{ fullName }}</strong>
            <span v-if="type == 'harmony-like'"> {{ $t('notify.liked') }} </span>
            <span v-if="type == 'harmony-follow'"> {{ $t('notify.followed') }} </span>
            <a :href="'https://harmony.fan/' + siteName">{{ siteName }}</a>
        </div>

        <div v-if="type == 'harmony-upload-logo'">
            <a :href="'https://harmony.fan/' + siteName">{{ siteName }}</a>
            <span> {{ $t('notify.activity-logo') }} </span>
        </div>

        <div v-if="type == 'harmony-upload-background'">
            <div v-if="isNew">
                <a :href="'https://harmony.fan/' + siteName">{{ siteName }}</a>
                <span> {{ $t('notify.activity-background-new') }} </span>
            </div>
            <div v-else>
                <a :href="'https://harmony.fan/' + siteName">{{ siteName }}</a>
                <span> {{ $t('notify.activity-background-updated') }} </span>
            </div>
        </div>

        <div v-if="type == 'harmony-upload-video'">
            <div v-if="isNew">
                <a :href="'https://harmony.fan/' + siteName">{{ siteName }}</a>
                <span> {{ $t('notify.activity-video-new') }} </span>
            </div>
            <div v-else>
                <a :href="'https://harmony.fan/' + siteName">{{ siteName }}</a>
                <span> {{ $t('notify.activity-video-updated') }} </span>
            </div>
        </div>

        <div v-if="type == 'harmony-upload-gallery'">
            <div v-if="isNew">
                <a :href="'https://harmony.fan/' + siteName">{{ siteName }}</a>
                <span> {{ $t('notify.activity-gallery-new') }} </span>
            </div>
            <div v-else>
                <a :href="'https://harmony.fan/' + siteName">{{ siteName }}</a>
                <span> {{ $t('notify.activity-gallery-updated') }} </span>
            </div>
        </div>

        <div v-if="type == 'harmony-upload-schedule'">
            <div v-if="isNew">
                <a :href="'https://harmony.fan/' + siteName">{{ siteName }}</a>
                <span> {{ $t('notify.activity-schedule-new') }} </span>
            </div>
            <div v-else>
                <a :href="'https://harmony.fan/' + siteName">{{ siteName }}</a>
                <span> {{ $t('notify.activity-schedule-updated') }} </span>
            </div>
        </div>

        <div v-if="type == 'harmony-upload-social'">
            <a :href="'https://harmony.fan/' + siteName">{{ siteName }}</a>
            <span> {{ $t('notify.activity-social') }} </span>
        </div>

    </div>
</template>

<script>
export default {
    name: 'NotifyUsersComponent',
    props: {
        type: {
            type: String,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        site: {
            type: Object,
            required: true,
        },
        isNew: {
            type: Boolean,
            required: true,
        }
    },
    computed: {
        fullName() {
            return `${this.user.first_name} ${this.user.last_name}`;
        },
        siteName() {
            return `${this.site.name}`;
        },
    },
};
</script>

<style scoped>
a {
    color: #8E84C0 !important;
    text-decoration: none;
    font-weight: 600;
}
</style>
